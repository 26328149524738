import { orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// import { Theme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//   },
// });

// declare module '@mui/styles/defaultTheme' {
//   interface DefaultTheme extends Theme {}
// }

const theme = createTheme({
  palette: {
    background: {
      default: `#222222`,
    },
    text: {
      primary: orange[800],
      secondary: orange[500],
    },
    primary: {
      main: orange[800],
    },
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: orange[800],
    //     },
    //   },
    // },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: orange[800],
        },
      },
    },
  },
});

export default theme;
